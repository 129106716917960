// source: https://www.healthhub.sg/directory/hospitals

export const hospitalList = [
  // {
  //   name: 'Alexandra Hospital',
  //   code: 'ALEXANDRA',
  //   hospitalCode: 'H3',
  //   address: '378 ALEXANDRA ROAD ALEXANDRA HOSPITAL Singapore 159964',
  //   postalCode: 159964,
  //   location: {
  //     longitude: 103.801111,
  //     latitude: 1.286583,
  //   },
  // },
  {
    name: 'Singapore General Hospital',
    code: 'SGH',
    hospitalCode: 'H1',
    label: 'H1: SGH',
    address: 'Outram Road Singapore 169608',
    postalCode: 169608,
    location: {
      longitude: 103.834726,
      latitude: 1.279079,
    },
  },
  {
    name: 'Khoo Teck Puat Hospital',
    code: 'KTPH',
    hospitalCode: 'H2',
    label: 'H2: KTPH',
    address: '90 YISHUN CENTRAL Singapore 768828',
    postalCode: 768828,
    location: {
      longitude: 103.837815,
      latitude: 1.424067,
    },
  },
  {
    name: 'Tan Tock Seng Hospital',
    code: 'TTSH',
    hospitalCode: 'H4',
    label: 'H4: TTSH',
    address: '11 Jalan Tan Tock Seng, Singapore 308433',
    postalCode: 308433,
    location: {
      longitude: 103.845812,
      latitude: 1.321364,
    },
  },
  {
    name: 'National Centre for Infectious Disease',
    code: 'NCID',
    hospitalCode: 'H4A',
    label: 'H4A: NCID',
    address: '16 Jalan Tan Tock Seng, Singapore 308442',
    postalCode: 308442,
    location: {
      longitude: 103.84742,
      latitude: 1.321833,
    },
  },
  {
    name: 'Changi General Hospital',
    code: 'CGH',
    hospitalCode: 'H5',
    label: 'H5: CGH',
    address: '2 SIMEI STREET 3 Singapore 529889',
    postalCode: 529889,
    location: {
      longitude: 103.949575,
      latitude: 1.340175,
    },
  },
  {
    name: 'National University Hospital',
    code: 'NUH',
    hospitalCode: 'H6',
    label: 'H6: NUH',
    address: '5 LOWER KENT RIDGE ROAD Singapore 119074',
    postalCode: 119074,
    location: {
      longitude: 103.78289,
      latitude: 1.29464,
    },
  },
  {
    name: 'Kandang Kerbau Hospital',
    code: 'KKH',
    hospitalCode: 'H7',
    label: 'H7: KKH',
    address: '100 BUKIT TIMAH ROAD Singapore 229899',
    postalCode: 229899,
    location: {
      longitude: 103.847129,
      latitude: 1.30975,
    },
  },
  {
    name: 'Kandang Kerbau Hospital',
    code: 'KKH',
    hospitalCode: 'H7A',
    label: 'H7A: KKH',
    address: '100 BUKIT TIMAH ROAD Singapore 229899',
    postalCode: 229899,
    location: {
      longitude: 103.847129,
      latitude: 1.30975,
    },
  },

  {
    name: 'Ng Teng Fong General Hospital',
    code: 'NTFGH',
    hospitalCode: 'H9',
    label: 'H9: NTFGH',
    address: '1 JURONG EAST STREET 21 Singapore 609606',
    postalCode: 609606,
    location: {
      longitude: 103.74568,
      latitude: 1.333683,
    },
  },
  // {
  //   name: 'Raffles Hospital',
  //   code: 'RAFFLES',
  //   hospitalCode: 'H10',
  //   address: '585 NORTH BRIDGE ROAD RAFFLES HOSPITAL Singapore 188770',
  //   postalCode: 188770,
  //   location: {
  //     longitude: 103.857352,
  //     latitude: 1.301191,
  //   },
  // },
  {
    name: 'Sengkang General Hospital',
    code: 'SKGH',
    hospitalCode: 'H11',
    label: 'H11: SKGH',
    address: '110 SENGKANG EAST WAY Singapore 544886',
    postalCode: 544886,
    location: {
      longitude: 103.893582,
      latitude: 1.39565,
    },
  },

  {
    name: 'Woodlands Health Campus',
    code: 'WHC',
    hospitalCode: 'H12',
    label: 'H12: WHC',
    address: '2 YISHUN CENTRAL 2 Singapore 768024',
    postalCode: 768024,
    location: {
      longitude: 103.7935399,
      latitude: 1.4247337,
    },
  },
];

export const hospitalCount = hospitalList.length;

export const hospitalMap = {
  H1: 'H1: SGH',
  H2: 'H2: KTPH',
  // H3: 'H3: ALEXANDRA',
  H4: 'H4: TTSH',
  H4A: 'H4A: NCID',
  H5: 'H5: CGH',
  H6: 'H6: NUH',
  H7: 'H7: KKH',
  H7A: 'H7A: KKH',
  H9: 'H9: NTFGH',
  // H10: 'H10: RAFFLES',
  H11: 'H11: SKGH',
  H12: 'H12: WHC',
};

export const hospitalName = {
  H1: 'Singapore General Hospital A&E',
  H2: 'Khoo Teck Puat Hospital A&E',
  // H3: 'Alexandra Hospital A&E',
  H4: 'Tan Tock Seng Hospital A&E',
  H4A: 'National Centre for Infectious Disease',
  H5: 'Changi General Hospital A&E',
  H6: 'National University Hospital A&E',
  H7: "Kandang Kerbau Hospital Women's A&E",
  H7A: "Kandang Kerbau Hospital Children's A&E",
  H9: 'Ng Teng Fong General Hospital A&E',
  // H10: 'Raffles Hospital A&E',
  H11: 'Sengkang General Hospital A&E',
  H12: 'Woodlands Health Campus A&E',
};

Object.freeze(hospitalList);
Object.freeze(hospitalMap);
Object.freeze(hospitalName);

// export const hospitalList = [
//     // {
//     //     name: 'Alexandra Hospital',
//     //     address: '378 ALEXANDRA ROAD ALEXANDRA HOSPITAL Singapore 159964',
//     //     postalCode: 159964,
//     //     location: {
//     //         longitude: 103.801111,
//     //         latitude: 1.286583
//     //     }
//     // },
//     // {
//     //     name: 'Ang Mo Kio - Thye Hua Kwan Hospital',
//     //     address: '17 ANG MO KIO AVENUE 9 Singapore 569766',
//     //     postalCode: 569766,
//     //     location: {
//     //         longitude: 103.840436,
//     //         latitude: 1.384287,
//     //     }
//     // },
//     // {
//     //     name: 'Bright Vision Community Hospital',
//     //     address: '5 LORONG NAPIRI Singapore 547530',
//     //     postalCode: 547530,
//     //     location: {
//     //         longitude: 103.878122,
//     //         latitude: 1.372226,
//     //     }
//     // },
//     {
//         name: "Changi General Hospital",
//         code: "CGH",
//         address: "2 SIMEI STREET 3 Singapore 529889",
//         postalCode: 529889,
//         location: {
//             longitude: 103.949575,
//             latitude: 1.340175,
//         },
//     },
//     // {
//     //     name: 'Complex Medical Centre',
//     //     address: '982 UPPER CHANGI ROAD NORTH Singapore 507709',
//     //     postalCode: 507709,
//     //     location: {
//     //         longitude: 103.971572,
//     //         latitude: 1.357961,
//     //     }
//     // },
//     // {
//     //     name: 'Crawfurd Hospital',
//     //     address: '19 ADAM ROAD Singapore 289891',
//     //     postalCode: 289891,
//     //     location: {
//     //         longitude: 103.813421,
//     //         latitude: 1.326843,
//     //     }
//     // },
//     // {
//     //     name: 'Farrer Park Hospital',
//     //     address: '1 FARRER PARK STATION ROAD,, #08-21-09-21,10-21,11-5-8, #01-21&22,#02-01,#03-21,#04-21,#04-K1/K6,#05- Singapore 217562',
//     //     postalCode: 217562,
//     //     location: {
//     //         longitude: 103.853855,
//     //         latitude: 1.312267,
//     //     }
//     // },
//     // {
//     //     name: 'Gleneagles Hospital',
//     //     address: '6A NAPIER ROAD Singapore 258500',
//     //     postalCode: 258500,
//     //     location: {
//     //         longitude: 103.819614,
//     //         latitude: 1.307491,
//     //     }
//     // },
//     // {
//     //     name: 'Institute Of Mental Health / Woodbridge Hospital',
//     //     address: '10 BUANGKOK VIEW Singapore 539747',
//     //     postalCode: 539747,
//     //     location: {
//     //         longitude: 103.884579,
//     //         latitude: 1.382684,
//     //     }
//     // },
//     // {
//     //     name: 'Jurong Community Hospital',
//     //     address: '1 JURONG EAST STREET 21 Singapore 609606',
//     //     postalCode: 609606,
//     //     location: {
//     //         longitude: 103.745680,
//     //         latitude: 1.333683,
//     //     }
//     // },
//     {
//         name: "Khoo Teck Puat Hospital",
//         code: "KTPH",
//         address: "90 YISHUN CENTRAL Singapore 768828",
//         postalCode: 768828,
//         location: {
//             longitude: 103.837815,
//             latitude: 1.424067,
//         },
//     },
//     {
//         name: "KK Women's And Children's Hospital",
//         code: "KK",
//         address: "100 BUKIT TIMAH ROAD Singapore 229899",
//         postalCode: 229899,
//         location: {
//             longitude: 103.847129,
//             latitude: 1.30975,
//         },
//     },
//     // {
//     //     name: 'Mount Alvernia Hospital',
//     //     address: '820 THOMSON ROAD Singapore 574623',
//     //     postalCode: 574623,
//     //     location: {
//     //         longitude: 103.837703,
//     //         latitude: 1.342122,
//     //     }
//     // },
//     // {
//     //     name: 'Mount Elizabeth Hospital',
//     //     address: '3 Mount Elizabeth Singapore 228510',
//     //     postalCode: 228510,
//     //     location: {
//     //         longitude: 103.835528,
//     //         latitude: 1.305469,
//     //     }
//     // },
//     // {
//     //     name: 'Mount Elizabeth Novena Hospital',
//     //     address: '38 IRRAWADDY ROAD Singapore 329563',
//     //     postalCode: 329563,
//     //     location: {
//     //         longitude: 103.844127,
//     //         latitude: 1.322209,
//     //     }
//     // },
//     // {
//     //     name: 'National Heart Centre Singapore',
//     //     address: '5 HOSPITAL DRIVE Singapore 169609',
//     //     postalCode: 169609,
//     //     location: {
//     //         longitude: 103.835960,
//     //         latitude: 1.278559,
//     //     }
//     // },
//     {
//         name: "National University Hospital",
//         code: "NUH",
//         address: "5 LOWER KENT RIDGE ROAD Singapore 119074",
//         postalCode: 119074,
//         location: {
//             longitude: 103.78289,
//             latitude: 1.29464,
//         },
//     },
//     {
//         name: "Ng Teng Fong General Hospital",
//         code: "NTFGH",
//         address: "1 JURONG EAST STREET 21 Singapore 609606",
//         postalCode: 609606,
//         location: {
//             longitude: 103.74568,
//             latitude: 1.333683,
//         },
//     },
//     // {
//     //     name: 'Outram Community Hospital',
//     //     address: '10 HOSPITAL BOULEVARD Singapore 168582',
//     //     postalCode: 168582,
//     //     location: {
//     //         longitude: 103.835303,
//     //         latitude: 1.278093,
//     //     }
//     // },
//     // {
//     //     name: 'Parkway East Hospital',
//     //     address: '321 JOO CHIAT PLACE PARKWAY EAST HOSPITAL Singapore 427990',
//     //     postalCode: 427990,
//     //     location: {
//     //         longitude: 103.908823,
//     //         latitude: 1.315180,
//     //     }
//     // },
//     // {
//     //     name: 'Raffles Hospital',
//     //     address: '585 NORTH BRIDGE ROAD RAFFLES HOSPITAL Singapore 188770',
//     //     postalCode: 188770,
//     //     location: {
//     //         longitude: 103.857352,
//     //         latitude: 1.301191,
//     //     }
//     // },
//     // {
//     //     name: 'Ren Ci Community Hospital',
//     //     address: '71 IRRAWADDY ROAD Singapore 329562',
//     //     postalCode: 329562,
//     //     location: {
//     //         longitude: 103.846070,
//     //         latitude: 1.322897,
//     //     }
//     // },
//     // {
//     //     name: 'Sengkang Community Hospital',
//     //     address: '1 ANCHORVALE STREET Singapore 544835',
//     //     postalCode: 544835,
//     //     location: {
//     //         longitude: 103.891989,
//     //         latitude: 1.396161,
//     //     }
//     // },
//     {
//         name: "Sengkang General Hospital",
//         code: "SKGH",
//         address: "110 SENGKANG EAST WAY Singapore 544886",
//         postalCode: 544886,
//         location: {
//             longitude: 103.893582,
//             latitude: 1.39565,
//         },
//     },
//     // {
//     //     name: 'Singapore General Hospital',
//     //     address: '- Outram Road Singapore 169608',
//     //     postalCode: 169608,
//     //     location: {
//     //         longitude: 103.834726,
//     //         latitude: 1.279079,
//     //     }
//     // },
//     // {
//     //     name: 'St Andrew\'s Community Hospital',
//     //     address: '8 SIMEI STREET 3 Singapore 529895',
//     //     postalCode: 529895,
//     //     location: {
//     //         longitude: 103.948889,
//     //         latitude: 1.341753,
//     //     }
//     // },
//     // {
//     //     name: 'St. Luke\'s Hospital',
//     //     address: '2 BUKIT BATOK STREET 11 Singapore 659674',
//     //     postalCode: 659674,
//     //     location: {
//     //         longitude: 103.741838,
//     //         latitude: 1.349559,
//     //     }
//     // },
//     {
//         name: "Tan Tock Seng Hospital",
//         code: "TTSH",
//         address: "11 JALAN TAN TOCK SENG Singapore 308433",
//         postalCode: 308433,
//         location: {
//             longitude: 103.845812,
//             latitude: 1.321364,
//         },
//     },
//     // {
//     //     name: 'Tan Tock Seng Hospital Rehabilitation Centre',
//     //     address: '17 ANG MO KIO AVENUE 9 ANG MO KIO THYE HUA KWAN HOSPITAL Singapore 569766',
//     //     postalCode: 569766,
//     //     location: {
//     //         longitude: 103.840436,
//     //         latitude: 1.384287,
//     //     }
//     // },
//     // {
//     //     name: 'Tan Tock Seng Hospital Subacute Wards',
//     //     address: '71 Irrawaddy Road Ren Ci Community Hospital (Wards 6-10) Singapore 32956',
//     //     postalCode: 32956,
//     //     location: {
//     //         longitude: 103.846070,
//     //         latitude: 1.322897,
//     //     }
//     // },
//     // {
//     //     name: 'Thomson Medical Centre',
//     //     address: '339 THOMSON ROAD Singapore 307677',
//     //     postalCode: 307677,
//     //     location: {
//     //         longitude: 103.841588,
//     //         latitude: 1.325210,
//     //     }
//     // },
//     // {
//     //     name: 'Yishun Community Hospital',
//     //     address: '2 YISHUN CENTRAL 2 Singapore 768024',
//     //     postalCode: 768024,
//     //     location: {
//     //         longitude: 103.837215,
//     //         latitude: 1.424314,
//     //     }
//     // },
//     {
//         name: "Woodlands Health Campus",
//         code: "WHC",
//         address: "2 YISHUN CENTRAL 2 Singapore 768024",
//         postalCode: 768024,
//         location: {
//             longitude: 103.7935399,
//             latitude: 1.4247337,
//         },
//     },
// ];
