export const LOGIN_PATH = '/login';
export const LOGIN_PATHNAME = 'login';

export enum Paths {
  login = '/login',
  home = '/tracking',
  callback = '/callback',
  grouphome = '/system/user-accounts/group',
}

export enum PathNames {
  login = 'login',
  home = 'tracking',
  callback = 'callback',
}
