import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IntersectionPipes } from './intersections.pipe';
import { IntersectionPropertyPipe } from './intersectionProperty.pipe';
import { IntersectionCodePipe } from './intersectionCode.pipe';
import { IntersectionNamePipe } from './intersectionName.pipe';
import { IncidentPriorityDescriptionPipe } from './incidentPriorityDescription.pipe';
import { FilesizePipe } from './filesize.pipe';
import { HospitalLabelPipe } from './hospital-label.pipe';
import { HospitalNamePipe } from './hospital-name.pipe';
import { JunctionNamePipe } from './junctionName.pipe';
import { HospitalCodeJunctionPipe } from './hospitalCodeJunctionName.pipe';
import { JunctionIdPipe } from './junctionId.pipe';
import { MultipleJunctionNamePipe } from './multipleJunctionName.pipe';

@NgModule({
  declarations: [
    IntersectionPipes,
    IntersectionPropertyPipe,
    IntersectionCodePipe,
    IntersectionNamePipe,
    IncidentPriorityDescriptionPipe,
    FilesizePipe,
    HospitalLabelPipe,
    HospitalNamePipe,
    JunctionNamePipe,
    HospitalCodeJunctionPipe,
    JunctionIdPipe,
    MultipleJunctionNamePipe,
  ],
  imports: [CommonModule],
  exports: [
    IntersectionPipes,
    IntersectionPropertyPipe,
    IntersectionCodePipe,
    IntersectionNamePipe,
    IncidentPriorityDescriptionPipe,
    FilesizePipe,
    HospitalLabelPipe,
    JunctionNamePipe,
    HospitalCodeJunctionPipe,
    JunctionIdPipe,
    MultipleJunctionNamePipe,
  ],
})
export class CommonPipesModule {}
