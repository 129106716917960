import { Injectable, inject } from '@angular/core';
import { Router } from '@angular/router';
import { UsersService } from '@openapi/keycloak';
import {
  EventTypes,
  LoginResponse,
  LogoutAuthOptions,
  OidcSecurityService,
  PublicEventsService,
} from 'angular-auth-oidc-client';
import { environment } from 'environments/environment';
import { Observable, ReplaySubject, of } from 'rxjs';
import {
  catchError,
  filter,
  first,
  map,
  switchMap,
  take,
} from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { PathNames, Paths } from '@constant/routes';
import { MatDialog } from '@angular/material/dialog';
import { CommonService } from '../common.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root',
})
export class AuthOidcService {
  private readonly oidcSecurityService = inject(OidcSecurityService);
  private readonly publicEventsService = inject(PublicEventsService);
  private readonly commonService = inject(CommonService);
  private readonly router = inject(Router);
  private readonly httpClient = inject(HttpClient);
  private readonly dialog = inject(MatDialog);
  private readonly snackbar = inject(MatSnackBar);

  configuration$ = this.oidcSecurityService.getConfiguration();

  displayName;

  constructor() {
    this.setupEvents();
  }

  userData$ = this.oidcSecurityService.userData$;

  public get isAuthenticated$(): Observable<boolean> {
    return this.oidcSecurityService.isAuthenticated$.pipe(
      map(({ isAuthenticated }) => {
        console.warn('getter auth: ', isAuthenticated);
        if (!isAuthenticated) {
          // this.refreshSession();
        }
        return isAuthenticated;
      })
    );
  }

  login(): void {
    this.oidcSecurityService.authorize();
  }

  kcUserLogin(): void {
    this.oidcSecurityService.authorize(undefined, {
      customParams: {
        prompt: 'select_account',
        kc_idp_hint: '',
      },
    });
  }

  kcChangePassword(): void {
    this.oidcSecurityService.authorize(undefined, {
      customParams: {
        // prompt: 'select_account',
        kc_idp_hint: '',
        kc_action: 'UPDATE_PASSWORD',
      },
    });
  }

  kcSet2fa(): void {
    this.oidcSecurityService.authorize(undefined, {
      customParams: {
        // prompt: 'select_account',
        kc_idp_hint: '',
        kc_action: 'CONFIGURE_TOTP',
      },
    });
  }

  kcUpdateProfile(): void {
    this.oidcSecurityService.authorize(undefined, {
      redirectUrl: environment.keycloak.redirectUrl,
      customParams: {
        // prompt: 'none',
        kc_idp_hint: '',
        kc_action: 'UPDATE_PROFILE',
      },
    });
  }

  silentAuthorize() {
    this.oidcSecurityService.authorize(undefined, {
      customParams: {
        prompt: 'none',
        kc_idp_hint: environment.keycloak.idp,
      },
    });
  }

  forceRefresh(): Observable<LoginResponse> {
    return this.oidcSecurityService.forceRefreshSession();
  }

  refreshSession(): Observable<LoginResponse | boolean> {
    // this.oidcSecurityService.forceRefreshSession().subscribe({
    //   next: (result) => {
    //     console.warn('Token Refreshed: ', result);
    //     return true;
    //   },
    //   error: (err) => console.warn('Token Refresh Failed: ', err),
    // });
    return this.oidcSecurityService.forceRefreshSession().pipe(
      switchMap(loginResponse => {
        console.warn('Token Refreshed: ', loginResponse);
        const { isAuthenticated, errorMessage } = loginResponse;
        if (isAuthenticated) {
          return of(loginResponse);
        }
        console.warn('Token Refresh failed', errorMessage);
        return of(loginResponse);
      }),
      catchError(err => {
        console.warn('Token Refresh Failed: ', err);
        return of(false);
      })
    );
  }

  refresh() {
    console.warn('Start Refresh Session');
    this.refreshSession().subscribe(res => {
      // console.warn('Refreshed Session: ', res);
    });
  }

  logout(): void {
    // this.oidcSecurityService.logoff().subscribe(result => console.log(result));
    this.oidcSecurityService.logoffAndRevokeTokens().subscribe(result => {
      console.warn('Auth: OIDC logout: ', result);
      this.commonService.idleStop();
    });
  }

  logoutLocal(): void {
    this.oidcSecurityService.logoffLocal();
    this.commonService.idleStop();
    this.router.navigate(['/login']);
  }

  logoutAuthOptions: LogoutAuthOptions = {
    // customParams: {
    //   client_id: environment.keycloak.clientId,
    // },
    // logoffMethod: 'POST',
  };

  revokeRefreshToken(): void {
    this.oidcSecurityService
      .revokeRefreshToken()
      .subscribe(result => console.log(result));
  }

  revokeAccessToken(): void {
    this.oidcSecurityService
      .revokeAccessToken()
      .subscribe(result => console.log(result));
  }

  getUserData() {
    const data = this.oidcSecurityService.getUserData();
    console.log('User Data', data);
  }

  checkAuth() {
    return this.oidcSecurityService.checkAuth();
  }

  isAuthenticated() {
    return this.oidcSecurityService.isAuthenticated();
  }

  // checkAuth() {
  //   this.oidcSecurityService.checkAuth().subscribe({
  //     next: ({ isAuthenticated, userData }) => {
  //       if (isAuthenticated) {
  //         const { name } = userData || {};
  //         if (name) {
  //           localStorage.setItem('displayName', name);
  //         }
  //       }

  //       console.warn('app authenticated', isAuthenticated);
  //     },
  //     error: err => console.warn('app authentication fail: ', err),
  //   });
  // }

  checkIfAuthenticated() {
    return this.oidcSecurityService.checkAuth().pipe(
      map(({ isAuthenticated }) => {
        return isAuthenticated;
      })
    );
  }

  setupEvents() {
    // this.monitorTokenExpired();
    this.monitorSilentRenew();

    // this.publicEventsService
    //   .registerForEvents()
    //   .pipe(
    //     filter(
    //       notification => notification.type === EventTypes.CheckingAuthFinished
    //     )
    //   )
    //   .subscribe(value =>
    //     console.warn('OidcEvent - Check Auth Finished: ', value)
    //   );

    // this.publicEventsService
    //   .registerForEvents()
    //   .pipe(
    //     filter(
    //       notification => notification.type === EventTypes.SilentRenewStarted
    //     )
    //   )
    //   .subscribe(value =>
    //     console.warn('OidcEvent - SilentRenewStarted: ', value)
    //   );

    // this.publicEventsService
    //   .registerForEvents()
    //   .pipe(
    //     filter(notification => notification.type === EventTypes.TokenExpired)
    //   )
    //   .subscribe(value => console.warn('OidcEvent - TokenExpired: ', value));

    // this.publicEventsService
    //   .registerForEvents()
    //   .pipe(
    //     filter(notification => notification.type === EventTypes.UserDataChanged)
    //   )
    //   .subscribe(({ type, value }) =>
    //     console.warn(
    //       'OidcEvent - UserDataChanged: ',
    //       type,
    //       JSON.stringify(value)
    //     )
    //   );
  }

  monitorTokenExpired() {
    this.publicEventsService
      .registerForEvents()
      .pipe(
        filter(notification => notification.type === EventTypes.TokenExpired)
      )
      .subscribe(({ type, value }) => {
        console.warn('OidcEvent - TokenExpired: ', type, JSON.stringify(value));
      });
  }

  /**
   * Logout user if silent renew fails
   * Failed silent renew means user was not provided a new valid access token
   */
  monitorSilentRenew() {
    this.publicEventsService
      .registerForEvents()
      .pipe(
        filter(
          notification => notification.type === EventTypes.SilentRenewFailed
        )
      )
      .subscribe(({ type, value }) => {
        console.warn(
          'OidcEvent - Silent Renew failed: ',
          type,
          JSON.stringify(value)
        );
        this.snackbar.open(
          'Your session has expired. Please login and try again.',
          null,
          {
            duration: 5000,
            panelClass: 'custom-snack-bar-panel-error',
          }
        );
        this.logoutLocal();
      });
  }

  monitorSession() {
    this.oidcSecurityService.checkSessionChanged$.subscribe(checkSession => {
      console.warn('Check Session Event', checkSession);
    });
  }

  getUserInfo() {
    this.oidcSecurityService.getConfiguration().subscribe(config => {
      const { authWellknownEndpoints } = config || {};
      const { userInfoEndpoint } = authWellknownEndpoints || {};
      console.warn('config', config, userInfoEndpoint);
      if (userInfoEndpoint) {
        this.httpClient.get(userInfoEndpoint).subscribe(userInfo => {
          console.warn('Get User Info: ', userInfo);
        });
      }
    });
  }

  listenStorageEvent() {
    const storageListener = (event: StorageEvent) => {
      const { storageArea, key } = event;
      if (storageArea === localStorage) {
        // console.warn('localstorage event: ', key);

        if (key === environment.keycloak.configId) {
          console.warn('localstorage oidc: ', key);
          this.oidcSecurityService
            .isAuthenticated()
            .subscribe(isAuthenticated => {
              console.warn('localstorage oidc route: ', this.router.url);
              if (isAuthenticated) {
                if (this.router.url?.includes(PathNames.login)) {
                  this.dialog.closeAll();
                  this.router.navigate([Paths.home]).then(() => {
                    document.location.reload();
                  });
                }
              } else {
                if (!this.router.url?.includes(PathNames.login)) {
                  this.dialog.closeAll();
                  this.router.navigate([Paths.login]).then(() => {
                    document.location.reload();
                  });
                }
              }
            });
        }
      }
    };

    window.removeEventListener('storage', storageListener);
    window.addEventListener('storage', storageListener);
  }

  isUserAuthEvent() {
    return this.publicEventsService.registerForEvents().pipe(
      filter(notification => notification.type === EventTypes.UserDataChanged),
      map(({ value }) => {
        if (value !== null) {
          return true;
        }
        return false;
      })
    );
  }

  getUserRole() {
    return this.oidcSecurityService.getUserData().pipe(
      map(userData => {
        console.warn('Oidc Auth: Get User Data', userData);
        const { groups = [] } = userData || {};
        return groups;
      })
    );
  }

  getUserAppRole() {
    return this.oidcSecurityService.getUserData().pipe(
      map(userData => {
        const { groups = [] } = userData || {};
        // console.warn('Oidc Auth: Get User App Data', userData, groups);
        if (groups.indexOf('role-admin') > -1) {
          return 'admin';
        }
        return 'user';
      })
    );
  }
}
