const rootPath = location?.origin ?? 'https://tps.agilfms.org';
const wssPath = location?.host ?? 'tps.agilfms.org';

export const environment = {
  projectName: 'TPS',
  production: true,
  hmr: false,

  hereApiKey: 'GMwaocRFd7MYjesozSLak2VKgHQaxP0O43GpShenrvg',
  esriApiKey:
    'AAPK679ae36554034080ace4fe4afc0f7a6fS2ot1N3jrsFweuH6bO5QJbOYXo1nuWRiQK58AdDKpJr1z67bphWxhgIpp9laKneT',

  // apiTpsUri:
  //   'https://fms-tps-prod-backend-lb-1152632428.ap-southeast-1.elb.amazonaws.com:18081',

  // apiTpsUri: 'https://tps.ngbms-sg.com:8080/api',
  // apiAuthUri: 'https://tps.ngbms-sg.com:8080/oauth',
  // apiBaseUri: 'https://tps.ngbms-sg.com:8080',
  // apiUserUri: 'https://tps.ngbms-sg.com:8080/users',
  apiTpsUri: `${rootPath}/api/tps`,
  apiAuthUri: `${rootPath}/api/oauth'`,
  apiBaseUri: `${rootPath}/api`,
  apiUserUri: `${rootPath}/api/users`,
  reportUri: `${rootPath}/reports`,

  apiMapUri: 'https://map-tiles.agilfms.org',
  // apiMapUri: `${rootPath}/map`,

  // nodeApiBaseUri: 'https://tps.agilfms.org:444',
  // nodeUrlWs: 'wss://tps.agilfms.org:444/ws',

  // nodeApiBaseUri: 'http://localhost:4200/api/node',
  // nodeUrlWs: 'ws://localhost:4200/ws',

  nodeApiBaseUri: `${rootPath}/api/node`,
  nodeUrlWs: `wss://${wssPath}/ws`,

  // idleTimer: 1800,
  idleTimer: 21600,
  sessionExpiry: 60,
  keepAliveTimer: 600, // 10 mins

  map: {
    APIKey:
      'pk.eyJ1IjoiY2FzcGVybWFjYXRhbmdheSIsImEiOiJjanN2bTR1cnEwNHI5NDNvZnJhd2FkcmpoIn0.DkVZXWivnqAUOgBEGjQ5pA',
    boundingExtent: {
      minCoordinate: [103.59, 1.13],
      maxCoordinate: [104.1, 1.49],
    },
  },

  allowConcurrentLogin: true,

  timezone: '+0800',
  staticOnly: true,
  allowSimulation: false,
  showRseSlave: false,
  showUnassignedRse: true,
  baseHref: '',

  keycloak: {
    configId: 'tps-webapp',
    baseUrl: 'https://oauth2.agilfms.org/auth',
    authority: 'https://oauth2.agilfms.org/auth/realms/TPS',
    admin: 'https://oauth2.agilfms.org/auth/admin/realms/TPS',
    userUrl:
      'https://oauth2.agilfms.org/auth/realms/TPS/protocol/openid-connect/userinfo',
    homePath: '/tracking',
    redirectUrl: `${window.location.origin}/callback`,
    postLogoutRedirectUri: `${window.location.origin}/login`,
    realm: 'TPS',
    idp: 'AzureSAML',
    idpLabel: 'Azure',
    clientId: 'tps-webapp',
  },
};
