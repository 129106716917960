import {
  FIRESTATION_NAMES,
  DIVISION_CODES,
  ACES_FIRESTATIONS,
} from 'app/shared/constant/fireStation';

export function getFireStation(fireStationCode: string) {
  if (!fireStationCode) return '';
  return FIRESTATION_NAMES[fireStationCode] ?? fireStationCode;
}

export function getDivision(divisionCode: string) {
  if (!divisionCode) return '';
  return DIVISION_CODES[divisionCode] ?? divisionCode;
}

export function transformAcesFirestation(acesCode: string) {
  if (!acesCode) return '';
  return ACES_FIRESTATIONS[acesCode] ?? acesCode;
}

export function getFirestationDivision(acesCode: string) {
  if (!acesCode) return '';
  const divisionCode = Math.floor((+acesCode / 10) % 10) * 10;
  return DIVISION_CODES[divisionCode] ?? '';
}
