/**
 * Keycloak Admin REST API
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec, HttpContext 
        }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';

// @ts-ignore
import { AccessToken } from '../model/accessToken';
// @ts-ignore
import { ClientRepresentation } from '../model/clientRepresentation';
// @ts-ignore
import { ClientScopeRepresentation } from '../model/clientScopeRepresentation';
// @ts-ignore
import { CredentialRepresentation } from '../model/credentialRepresentation';
// @ts-ignore
import { GlobalRequestResult } from '../model/globalRequestResult';
// @ts-ignore
import { IDToken } from '../model/iDToken';
// @ts-ignore
import { ManagementPermissionReference } from '../model/managementPermissionReference';
// @ts-ignore
import { ProtocolMapperEvaluationRepresentation } from '../model/protocolMapperEvaluationRepresentation';
// @ts-ignore
import { RoleRepresentation } from '../model/roleRepresentation';
// @ts-ignore
import { UserRepresentation } from '../model/userRepresentation';
// @ts-ignore
import { UserSessionRepresentation } from '../model/userSessionRepresentation';

// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { KeycloakConfiguration }                                     from '../configuration';



@Injectable({
  providedIn: 'root'
})
export class ClientsService {

    protected basePath = 'http://localhost';
    public defaultHeaders = new HttpHeaders();
    public configuration = new KeycloakConfiguration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string|string[], @Optional() configuration: KeycloakConfiguration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            const firstBasePath = Array.isArray(basePath) ? basePath[0] : undefined;
            if (firstBasePath != undefined) {
                basePath = firstBasePath;
            }

            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }


    // @ts-ignore
    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key, (value as Date).toISOString().substring(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * Get the client secret
     * @param realm realm name (not id!)
     * @param clientUuid id of client (not client-id!)
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public adminRealmsRealmClientsClientUuidClientSecretGet(realm: string, clientUuid: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<CredentialRepresentation>;
    public adminRealmsRealmClientsClientUuidClientSecretGet(realm: string, clientUuid: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<CredentialRepresentation>>;
    public adminRealmsRealmClientsClientUuidClientSecretGet(realm: string, clientUuid: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<CredentialRepresentation>>;
    public adminRealmsRealmClientsClientUuidClientSecretGet(realm: string, clientUuid: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (realm === null || realm === undefined) {
            throw new Error('Required parameter realm was null or undefined when calling adminRealmsRealmClientsClientUuidClientSecretGet.');
        }
        if (clientUuid === null || clientUuid === undefined) {
            throw new Error('Required parameter clientUuid was null or undefined when calling adminRealmsRealmClientsClientUuidClientSecretGet.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/admin/realms/${this.configuration.encodeParam({name: "realm", value: realm, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/clients/${this.configuration.encodeParam({name: "clientUuid", value: clientUuid, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/client-secret`;
        return this.httpClient.request<CredentialRepresentation>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Generate a new secret for the client
     * @param realm realm name (not id!)
     * @param clientUuid id of client (not client-id!)
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public adminRealmsRealmClientsClientUuidClientSecretPost(realm: string, clientUuid: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<CredentialRepresentation>;
    public adminRealmsRealmClientsClientUuidClientSecretPost(realm: string, clientUuid: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<CredentialRepresentation>>;
    public adminRealmsRealmClientsClientUuidClientSecretPost(realm: string, clientUuid: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<CredentialRepresentation>>;
    public adminRealmsRealmClientsClientUuidClientSecretPost(realm: string, clientUuid: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (realm === null || realm === undefined) {
            throw new Error('Required parameter realm was null or undefined when calling adminRealmsRealmClientsClientUuidClientSecretPost.');
        }
        if (clientUuid === null || clientUuid === undefined) {
            throw new Error('Required parameter clientUuid was null or undefined when calling adminRealmsRealmClientsClientUuidClientSecretPost.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/admin/realms/${this.configuration.encodeParam({name: "realm", value: realm, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/clients/${this.configuration.encodeParam({name: "clientUuid", value: clientUuid, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/client-secret`;
        return this.httpClient.request<CredentialRepresentation>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Invalidate the rotated secret for the client
     * @param realm realm name (not id!)
     * @param clientUuid id of client (not client-id!)
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public adminRealmsRealmClientsClientUuidClientSecretRotatedDelete(realm: string, clientUuid: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<any>;
    public adminRealmsRealmClientsClientUuidClientSecretRotatedDelete(realm: string, clientUuid: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<HttpResponse<any>>;
    public adminRealmsRealmClientsClientUuidClientSecretRotatedDelete(realm: string, clientUuid: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<HttpEvent<any>>;
    public adminRealmsRealmClientsClientUuidClientSecretRotatedDelete(realm: string, clientUuid: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<any> {
        if (realm === null || realm === undefined) {
            throw new Error('Required parameter realm was null or undefined when calling adminRealmsRealmClientsClientUuidClientSecretRotatedDelete.');
        }
        if (clientUuid === null || clientUuid === undefined) {
            throw new Error('Required parameter clientUuid was null or undefined when calling adminRealmsRealmClientsClientUuidClientSecretRotatedDelete.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/admin/realms/${this.configuration.encodeParam({name: "realm", value: realm, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/clients/${this.configuration.encodeParam({name: "clientUuid", value: clientUuid, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/client-secret/rotated`;
        return this.httpClient.request<any>('delete', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get the rotated client secret
     * @param realm realm name (not id!)
     * @param clientUuid id of client (not client-id!)
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public adminRealmsRealmClientsClientUuidClientSecretRotatedGet(realm: string, clientUuid: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<CredentialRepresentation>;
    public adminRealmsRealmClientsClientUuidClientSecretRotatedGet(realm: string, clientUuid: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<CredentialRepresentation>>;
    public adminRealmsRealmClientsClientUuidClientSecretRotatedGet(realm: string, clientUuid: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<CredentialRepresentation>>;
    public adminRealmsRealmClientsClientUuidClientSecretRotatedGet(realm: string, clientUuid: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (realm === null || realm === undefined) {
            throw new Error('Required parameter realm was null or undefined when calling adminRealmsRealmClientsClientUuidClientSecretRotatedGet.');
        }
        if (clientUuid === null || clientUuid === undefined) {
            throw new Error('Required parameter clientUuid was null or undefined when calling adminRealmsRealmClientsClientUuidClientSecretRotatedGet.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/admin/realms/${this.configuration.encodeParam({name: "realm", value: realm, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/clients/${this.configuration.encodeParam({name: "clientUuid", value: clientUuid, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/client-secret/rotated`;
        return this.httpClient.request<CredentialRepresentation>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param realm realm name (not id!)
     * @param clientUuid id of client (not client-id!)
     * @param clientScopeId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public adminRealmsRealmClientsClientUuidDefaultClientScopesClientScopeIdDelete(realm: string, clientUuid: string, clientScopeId: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<any>;
    public adminRealmsRealmClientsClientUuidDefaultClientScopesClientScopeIdDelete(realm: string, clientUuid: string, clientScopeId: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<HttpResponse<any>>;
    public adminRealmsRealmClientsClientUuidDefaultClientScopesClientScopeIdDelete(realm: string, clientUuid: string, clientScopeId: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<HttpEvent<any>>;
    public adminRealmsRealmClientsClientUuidDefaultClientScopesClientScopeIdDelete(realm: string, clientUuid: string, clientScopeId: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<any> {
        if (realm === null || realm === undefined) {
            throw new Error('Required parameter realm was null or undefined when calling adminRealmsRealmClientsClientUuidDefaultClientScopesClientScopeIdDelete.');
        }
        if (clientUuid === null || clientUuid === undefined) {
            throw new Error('Required parameter clientUuid was null or undefined when calling adminRealmsRealmClientsClientUuidDefaultClientScopesClientScopeIdDelete.');
        }
        if (clientScopeId === null || clientScopeId === undefined) {
            throw new Error('Required parameter clientScopeId was null or undefined when calling adminRealmsRealmClientsClientUuidDefaultClientScopesClientScopeIdDelete.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/admin/realms/${this.configuration.encodeParam({name: "realm", value: realm, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/clients/${this.configuration.encodeParam({name: "clientUuid", value: clientUuid, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/default-client-scopes/${this.configuration.encodeParam({name: "clientScopeId", value: clientScopeId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}`;
        return this.httpClient.request<any>('delete', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param realm realm name (not id!)
     * @param clientUuid id of client (not client-id!)
     * @param clientScopeId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public adminRealmsRealmClientsClientUuidDefaultClientScopesClientScopeIdPut(realm: string, clientUuid: string, clientScopeId: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<any>;
    public adminRealmsRealmClientsClientUuidDefaultClientScopesClientScopeIdPut(realm: string, clientUuid: string, clientScopeId: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<HttpResponse<any>>;
    public adminRealmsRealmClientsClientUuidDefaultClientScopesClientScopeIdPut(realm: string, clientUuid: string, clientScopeId: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<HttpEvent<any>>;
    public adminRealmsRealmClientsClientUuidDefaultClientScopesClientScopeIdPut(realm: string, clientUuid: string, clientScopeId: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<any> {
        if (realm === null || realm === undefined) {
            throw new Error('Required parameter realm was null or undefined when calling adminRealmsRealmClientsClientUuidDefaultClientScopesClientScopeIdPut.');
        }
        if (clientUuid === null || clientUuid === undefined) {
            throw new Error('Required parameter clientUuid was null or undefined when calling adminRealmsRealmClientsClientUuidDefaultClientScopesClientScopeIdPut.');
        }
        if (clientScopeId === null || clientScopeId === undefined) {
            throw new Error('Required parameter clientScopeId was null or undefined when calling adminRealmsRealmClientsClientUuidDefaultClientScopesClientScopeIdPut.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/admin/realms/${this.configuration.encodeParam({name: "realm", value: realm, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/clients/${this.configuration.encodeParam({name: "clientUuid", value: clientUuid, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/default-client-scopes/${this.configuration.encodeParam({name: "clientScopeId", value: clientScopeId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}`;
        return this.httpClient.request<any>('put', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get default client scopes.  Only name and ids are returned.
     * @param realm realm name (not id!)
     * @param clientUuid id of client (not client-id!)
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public adminRealmsRealmClientsClientUuidDefaultClientScopesGet(realm: string, clientUuid: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<Array<ClientScopeRepresentation>>;
    public adminRealmsRealmClientsClientUuidDefaultClientScopesGet(realm: string, clientUuid: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<Array<ClientScopeRepresentation>>>;
    public adminRealmsRealmClientsClientUuidDefaultClientScopesGet(realm: string, clientUuid: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<Array<ClientScopeRepresentation>>>;
    public adminRealmsRealmClientsClientUuidDefaultClientScopesGet(realm: string, clientUuid: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (realm === null || realm === undefined) {
            throw new Error('Required parameter realm was null or undefined when calling adminRealmsRealmClientsClientUuidDefaultClientScopesGet.');
        }
        if (clientUuid === null || clientUuid === undefined) {
            throw new Error('Required parameter clientUuid was null or undefined when calling adminRealmsRealmClientsClientUuidDefaultClientScopesGet.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/admin/realms/${this.configuration.encodeParam({name: "realm", value: realm, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/clients/${this.configuration.encodeParam({name: "clientUuid", value: clientUuid, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/default-client-scopes`;
        return this.httpClient.request<Array<ClientScopeRepresentation>>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete the client
     * @param realm realm name (not id!)
     * @param clientUuid id of client (not client-id!)
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public adminRealmsRealmClientsClientUuidDelete(realm: string, clientUuid: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<any>;
    public adminRealmsRealmClientsClientUuidDelete(realm: string, clientUuid: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<HttpResponse<any>>;
    public adminRealmsRealmClientsClientUuidDelete(realm: string, clientUuid: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<HttpEvent<any>>;
    public adminRealmsRealmClientsClientUuidDelete(realm: string, clientUuid: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<any> {
        if (realm === null || realm === undefined) {
            throw new Error('Required parameter realm was null or undefined when calling adminRealmsRealmClientsClientUuidDelete.');
        }
        if (clientUuid === null || clientUuid === undefined) {
            throw new Error('Required parameter clientUuid was null or undefined when calling adminRealmsRealmClientsClientUuidDelete.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/admin/realms/${this.configuration.encodeParam({name: "realm", value: realm, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/clients/${this.configuration.encodeParam({name: "clientUuid", value: clientUuid, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}`;
        return this.httpClient.request<any>('delete', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Create JSON with payload of example access token
     * @param realm realm name (not id!)
     * @param clientUuid id of client (not client-id!)
     * @param scope 
     * @param userId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public adminRealmsRealmClientsClientUuidEvaluateScopesGenerateExampleAccessTokenGet(realm: string, clientUuid: string, scope?: string, userId?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<AccessToken>;
    public adminRealmsRealmClientsClientUuidEvaluateScopesGenerateExampleAccessTokenGet(realm: string, clientUuid: string, scope?: string, userId?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<AccessToken>>;
    public adminRealmsRealmClientsClientUuidEvaluateScopesGenerateExampleAccessTokenGet(realm: string, clientUuid: string, scope?: string, userId?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<AccessToken>>;
    public adminRealmsRealmClientsClientUuidEvaluateScopesGenerateExampleAccessTokenGet(realm: string, clientUuid: string, scope?: string, userId?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (realm === null || realm === undefined) {
            throw new Error('Required parameter realm was null or undefined when calling adminRealmsRealmClientsClientUuidEvaluateScopesGenerateExampleAccessTokenGet.');
        }
        if (clientUuid === null || clientUuid === undefined) {
            throw new Error('Required parameter clientUuid was null or undefined when calling adminRealmsRealmClientsClientUuidEvaluateScopesGenerateExampleAccessTokenGet.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (scope !== undefined && scope !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>scope, 'scope');
        }
        if (userId !== undefined && userId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>userId, 'userId');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/admin/realms/${this.configuration.encodeParam({name: "realm", value: realm, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/clients/${this.configuration.encodeParam({name: "clientUuid", value: clientUuid, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/evaluate-scopes/generate-example-access-token`;
        return this.httpClient.request<AccessToken>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Create JSON with payload of example id token
     * @param realm realm name (not id!)
     * @param clientUuid id of client (not client-id!)
     * @param scope 
     * @param userId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public adminRealmsRealmClientsClientUuidEvaluateScopesGenerateExampleIdTokenGet(realm: string, clientUuid: string, scope?: string, userId?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<IDToken>;
    public adminRealmsRealmClientsClientUuidEvaluateScopesGenerateExampleIdTokenGet(realm: string, clientUuid: string, scope?: string, userId?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<IDToken>>;
    public adminRealmsRealmClientsClientUuidEvaluateScopesGenerateExampleIdTokenGet(realm: string, clientUuid: string, scope?: string, userId?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<IDToken>>;
    public adminRealmsRealmClientsClientUuidEvaluateScopesGenerateExampleIdTokenGet(realm: string, clientUuid: string, scope?: string, userId?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (realm === null || realm === undefined) {
            throw new Error('Required parameter realm was null or undefined when calling adminRealmsRealmClientsClientUuidEvaluateScopesGenerateExampleIdTokenGet.');
        }
        if (clientUuid === null || clientUuid === undefined) {
            throw new Error('Required parameter clientUuid was null or undefined when calling adminRealmsRealmClientsClientUuidEvaluateScopesGenerateExampleIdTokenGet.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (scope !== undefined && scope !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>scope, 'scope');
        }
        if (userId !== undefined && userId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>userId, 'userId');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/admin/realms/${this.configuration.encodeParam({name: "realm", value: realm, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/clients/${this.configuration.encodeParam({name: "clientUuid", value: clientUuid, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/evaluate-scopes/generate-example-id-token`;
        return this.httpClient.request<IDToken>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Create JSON with payload of example user info
     * @param realm realm name (not id!)
     * @param clientUuid id of client (not client-id!)
     * @param scope 
     * @param userId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public adminRealmsRealmClientsClientUuidEvaluateScopesGenerateExampleUserinfoGet(realm: string, clientUuid: string, scope?: string, userId?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<{ [key: string]: any; }>;
    public adminRealmsRealmClientsClientUuidEvaluateScopesGenerateExampleUserinfoGet(realm: string, clientUuid: string, scope?: string, userId?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<{ [key: string]: any; }>>;
    public adminRealmsRealmClientsClientUuidEvaluateScopesGenerateExampleUserinfoGet(realm: string, clientUuid: string, scope?: string, userId?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<{ [key: string]: any; }>>;
    public adminRealmsRealmClientsClientUuidEvaluateScopesGenerateExampleUserinfoGet(realm: string, clientUuid: string, scope?: string, userId?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (realm === null || realm === undefined) {
            throw new Error('Required parameter realm was null or undefined when calling adminRealmsRealmClientsClientUuidEvaluateScopesGenerateExampleUserinfoGet.');
        }
        if (clientUuid === null || clientUuid === undefined) {
            throw new Error('Required parameter clientUuid was null or undefined when calling adminRealmsRealmClientsClientUuidEvaluateScopesGenerateExampleUserinfoGet.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (scope !== undefined && scope !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>scope, 'scope');
        }
        if (userId !== undefined && userId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>userId, 'userId');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/admin/realms/${this.configuration.encodeParam({name: "realm", value: realm, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/clients/${this.configuration.encodeParam({name: "clientUuid", value: clientUuid, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/evaluate-scopes/generate-example-userinfo`;
        return this.httpClient.request<{ [key: string]: any; }>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Return list of all protocol mappers, which will be used when generating tokens issued for particular client.
     * This means protocol mappers assigned to this client directly and protocol mappers assigned to all client scopes of this client.
     * @param realm realm name (not id!)
     * @param clientUuid id of client (not client-id!)
     * @param scope 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public adminRealmsRealmClientsClientUuidEvaluateScopesProtocolMappersGet(realm: string, clientUuid: string, scope?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<Array<ProtocolMapperEvaluationRepresentation>>;
    public adminRealmsRealmClientsClientUuidEvaluateScopesProtocolMappersGet(realm: string, clientUuid: string, scope?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<Array<ProtocolMapperEvaluationRepresentation>>>;
    public adminRealmsRealmClientsClientUuidEvaluateScopesProtocolMappersGet(realm: string, clientUuid: string, scope?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<Array<ProtocolMapperEvaluationRepresentation>>>;
    public adminRealmsRealmClientsClientUuidEvaluateScopesProtocolMappersGet(realm: string, clientUuid: string, scope?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (realm === null || realm === undefined) {
            throw new Error('Required parameter realm was null or undefined when calling adminRealmsRealmClientsClientUuidEvaluateScopesProtocolMappersGet.');
        }
        if (clientUuid === null || clientUuid === undefined) {
            throw new Error('Required parameter clientUuid was null or undefined when calling adminRealmsRealmClientsClientUuidEvaluateScopesProtocolMappersGet.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (scope !== undefined && scope !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>scope, 'scope');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/admin/realms/${this.configuration.encodeParam({name: "realm", value: realm, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/clients/${this.configuration.encodeParam({name: "clientUuid", value: clientUuid, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/evaluate-scopes/protocol-mappers`;
        return this.httpClient.request<Array<ProtocolMapperEvaluationRepresentation>>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get effective scope mapping of all roles of particular role container, which this client is defacto allowed to have in the accessToken issued for him.
     * This contains scope mappings, which this client has directly, as well as scope mappings, which are granted to all client scopes, which are linked with this client.
     * @param realm realm name (not id!)
     * @param clientUuid id of client (not client-id!)
     * @param roleContainerId either realm name OR client UUID
     * @param scope 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public adminRealmsRealmClientsClientUuidEvaluateScopesScopeMappingsRoleContainerIdGrantedGet(realm: string, clientUuid: string, roleContainerId: string, scope?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<Array<RoleRepresentation>>;
    public adminRealmsRealmClientsClientUuidEvaluateScopesScopeMappingsRoleContainerIdGrantedGet(realm: string, clientUuid: string, roleContainerId: string, scope?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<Array<RoleRepresentation>>>;
    public adminRealmsRealmClientsClientUuidEvaluateScopesScopeMappingsRoleContainerIdGrantedGet(realm: string, clientUuid: string, roleContainerId: string, scope?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<Array<RoleRepresentation>>>;
    public adminRealmsRealmClientsClientUuidEvaluateScopesScopeMappingsRoleContainerIdGrantedGet(realm: string, clientUuid: string, roleContainerId: string, scope?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (realm === null || realm === undefined) {
            throw new Error('Required parameter realm was null or undefined when calling adminRealmsRealmClientsClientUuidEvaluateScopesScopeMappingsRoleContainerIdGrantedGet.');
        }
        if (clientUuid === null || clientUuid === undefined) {
            throw new Error('Required parameter clientUuid was null or undefined when calling adminRealmsRealmClientsClientUuidEvaluateScopesScopeMappingsRoleContainerIdGrantedGet.');
        }
        if (roleContainerId === null || roleContainerId === undefined) {
            throw new Error('Required parameter roleContainerId was null or undefined when calling adminRealmsRealmClientsClientUuidEvaluateScopesScopeMappingsRoleContainerIdGrantedGet.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (scope !== undefined && scope !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>scope, 'scope');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/admin/realms/${this.configuration.encodeParam({name: "realm", value: realm, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/clients/${this.configuration.encodeParam({name: "clientUuid", value: clientUuid, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/evaluate-scopes/scope-mappings/${this.configuration.encodeParam({name: "roleContainerId", value: roleContainerId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/granted`;
        return this.httpClient.request<Array<RoleRepresentation>>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get roles, which this client doesn\&#39;t have scope for and can\&#39;t have them in the accessToken issued for him.
     * Defacto all the other roles of particular role container, which are not in {@link #getGrantedScopeMappings()}
     * @param realm realm name (not id!)
     * @param clientUuid id of client (not client-id!)
     * @param roleContainerId either realm name OR client UUID
     * @param scope 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public adminRealmsRealmClientsClientUuidEvaluateScopesScopeMappingsRoleContainerIdNotGrantedGet(realm: string, clientUuid: string, roleContainerId: string, scope?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<Array<RoleRepresentation>>;
    public adminRealmsRealmClientsClientUuidEvaluateScopesScopeMappingsRoleContainerIdNotGrantedGet(realm: string, clientUuid: string, roleContainerId: string, scope?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<Array<RoleRepresentation>>>;
    public adminRealmsRealmClientsClientUuidEvaluateScopesScopeMappingsRoleContainerIdNotGrantedGet(realm: string, clientUuid: string, roleContainerId: string, scope?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<Array<RoleRepresentation>>>;
    public adminRealmsRealmClientsClientUuidEvaluateScopesScopeMappingsRoleContainerIdNotGrantedGet(realm: string, clientUuid: string, roleContainerId: string, scope?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (realm === null || realm === undefined) {
            throw new Error('Required parameter realm was null or undefined when calling adminRealmsRealmClientsClientUuidEvaluateScopesScopeMappingsRoleContainerIdNotGrantedGet.');
        }
        if (clientUuid === null || clientUuid === undefined) {
            throw new Error('Required parameter clientUuid was null or undefined when calling adminRealmsRealmClientsClientUuidEvaluateScopesScopeMappingsRoleContainerIdNotGrantedGet.');
        }
        if (roleContainerId === null || roleContainerId === undefined) {
            throw new Error('Required parameter roleContainerId was null or undefined when calling adminRealmsRealmClientsClientUuidEvaluateScopesScopeMappingsRoleContainerIdNotGrantedGet.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (scope !== undefined && scope !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>scope, 'scope');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/admin/realms/${this.configuration.encodeParam({name: "realm", value: realm, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/clients/${this.configuration.encodeParam({name: "clientUuid", value: clientUuid, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/evaluate-scopes/scope-mappings/${this.configuration.encodeParam({name: "roleContainerId", value: roleContainerId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/not-granted`;
        return this.httpClient.request<Array<RoleRepresentation>>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get representation of the client
     * @param realm realm name (not id!)
     * @param clientUuid id of client (not client-id!)
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public adminRealmsRealmClientsClientUuidGet(realm: string, clientUuid: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<ClientRepresentation>;
    public adminRealmsRealmClientsClientUuidGet(realm: string, clientUuid: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<ClientRepresentation>>;
    public adminRealmsRealmClientsClientUuidGet(realm: string, clientUuid: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<ClientRepresentation>>;
    public adminRealmsRealmClientsClientUuidGet(realm: string, clientUuid: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (realm === null || realm === undefined) {
            throw new Error('Required parameter realm was null or undefined when calling adminRealmsRealmClientsClientUuidGet.');
        }
        if (clientUuid === null || clientUuid === undefined) {
            throw new Error('Required parameter clientUuid was null or undefined when calling adminRealmsRealmClientsClientUuidGet.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/admin/realms/${this.configuration.encodeParam({name: "realm", value: realm, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/clients/${this.configuration.encodeParam({name: "clientUuid", value: clientUuid, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}`;
        return this.httpClient.request<ClientRepresentation>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param realm realm name (not id!)
     * @param clientUuid id of client (not client-id!)
     * @param providerId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public adminRealmsRealmClientsClientUuidInstallationProvidersProviderIdGet(realm: string, clientUuid: string, providerId: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<any>;
    public adminRealmsRealmClientsClientUuidInstallationProvidersProviderIdGet(realm: string, clientUuid: string, providerId: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<HttpResponse<any>>;
    public adminRealmsRealmClientsClientUuidInstallationProvidersProviderIdGet(realm: string, clientUuid: string, providerId: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<HttpEvent<any>>;
    public adminRealmsRealmClientsClientUuidInstallationProvidersProviderIdGet(realm: string, clientUuid: string, providerId: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<any> {
        if (realm === null || realm === undefined) {
            throw new Error('Required parameter realm was null or undefined when calling adminRealmsRealmClientsClientUuidInstallationProvidersProviderIdGet.');
        }
        if (clientUuid === null || clientUuid === undefined) {
            throw new Error('Required parameter clientUuid was null or undefined when calling adminRealmsRealmClientsClientUuidInstallationProvidersProviderIdGet.');
        }
        if (providerId === null || providerId === undefined) {
            throw new Error('Required parameter providerId was null or undefined when calling adminRealmsRealmClientsClientUuidInstallationProvidersProviderIdGet.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/admin/realms/${this.configuration.encodeParam({name: "realm", value: realm, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/clients/${this.configuration.encodeParam({name: "clientUuid", value: clientUuid, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/installation/providers/${this.configuration.encodeParam({name: "providerId", value: providerId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}`;
        return this.httpClient.request<any>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Return object stating whether client Authorization permissions have been initialized or not and a reference
     * @param realm realm name (not id!)
     * @param clientUuid id of client (not client-id!)
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public adminRealmsRealmClientsClientUuidManagementPermissionsGet(realm: string, clientUuid: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<ManagementPermissionReference>;
    public adminRealmsRealmClientsClientUuidManagementPermissionsGet(realm: string, clientUuid: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<ManagementPermissionReference>>;
    public adminRealmsRealmClientsClientUuidManagementPermissionsGet(realm: string, clientUuid: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<ManagementPermissionReference>>;
    public adminRealmsRealmClientsClientUuidManagementPermissionsGet(realm: string, clientUuid: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (realm === null || realm === undefined) {
            throw new Error('Required parameter realm was null or undefined when calling adminRealmsRealmClientsClientUuidManagementPermissionsGet.');
        }
        if (clientUuid === null || clientUuid === undefined) {
            throw new Error('Required parameter clientUuid was null or undefined when calling adminRealmsRealmClientsClientUuidManagementPermissionsGet.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/admin/realms/${this.configuration.encodeParam({name: "realm", value: realm, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/clients/${this.configuration.encodeParam({name: "clientUuid", value: clientUuid, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/management/permissions`;
        return this.httpClient.request<ManagementPermissionReference>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Return object stating whether client Authorization permissions have been initialized or not and a reference
     * @param realm realm name (not id!)
     * @param clientUuid id of client (not client-id!)
     * @param managementPermissionReference 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public adminRealmsRealmClientsClientUuidManagementPermissionsPut(realm: string, clientUuid: string, managementPermissionReference?: ManagementPermissionReference, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<ManagementPermissionReference>;
    public adminRealmsRealmClientsClientUuidManagementPermissionsPut(realm: string, clientUuid: string, managementPermissionReference?: ManagementPermissionReference, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<ManagementPermissionReference>>;
    public adminRealmsRealmClientsClientUuidManagementPermissionsPut(realm: string, clientUuid: string, managementPermissionReference?: ManagementPermissionReference, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<ManagementPermissionReference>>;
    public adminRealmsRealmClientsClientUuidManagementPermissionsPut(realm: string, clientUuid: string, managementPermissionReference?: ManagementPermissionReference, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (realm === null || realm === undefined) {
            throw new Error('Required parameter realm was null or undefined when calling adminRealmsRealmClientsClientUuidManagementPermissionsPut.');
        }
        if (clientUuid === null || clientUuid === undefined) {
            throw new Error('Required parameter clientUuid was null or undefined when calling adminRealmsRealmClientsClientUuidManagementPermissionsPut.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/admin/realms/${this.configuration.encodeParam({name: "realm", value: realm, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/clients/${this.configuration.encodeParam({name: "clientUuid", value: clientUuid, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/management/permissions`;
        return this.httpClient.request<ManagementPermissionReference>('put', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: managementPermissionReference,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Unregister a cluster node from the client
     * @param realm realm name (not id!)
     * @param clientUuid id of client (not client-id!)
     * @param node 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public adminRealmsRealmClientsClientUuidNodesNodeDelete(realm: string, clientUuid: string, node: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<any>;
    public adminRealmsRealmClientsClientUuidNodesNodeDelete(realm: string, clientUuid: string, node: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<HttpResponse<any>>;
    public adminRealmsRealmClientsClientUuidNodesNodeDelete(realm: string, clientUuid: string, node: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<HttpEvent<any>>;
    public adminRealmsRealmClientsClientUuidNodesNodeDelete(realm: string, clientUuid: string, node: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<any> {
        if (realm === null || realm === undefined) {
            throw new Error('Required parameter realm was null or undefined when calling adminRealmsRealmClientsClientUuidNodesNodeDelete.');
        }
        if (clientUuid === null || clientUuid === undefined) {
            throw new Error('Required parameter clientUuid was null or undefined when calling adminRealmsRealmClientsClientUuidNodesNodeDelete.');
        }
        if (node === null || node === undefined) {
            throw new Error('Required parameter node was null or undefined when calling adminRealmsRealmClientsClientUuidNodesNodeDelete.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/admin/realms/${this.configuration.encodeParam({name: "realm", value: realm, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/clients/${this.configuration.encodeParam({name: "clientUuid", value: clientUuid, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/nodes/${this.configuration.encodeParam({name: "node", value: node, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}`;
        return this.httpClient.request<any>('delete', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Register a cluster node with the client Manually register cluster node to this client - usually itâ€™s not needed to call this directly as adapter should handle by sending registration request to Keycloak
     * @param realm realm name (not id!)
     * @param clientUuid id of client (not client-id!)
     * @param requestBody 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public adminRealmsRealmClientsClientUuidNodesPost(realm: string, clientUuid: string, requestBody?: { [key: string]: string; }, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<any>;
    public adminRealmsRealmClientsClientUuidNodesPost(realm: string, clientUuid: string, requestBody?: { [key: string]: string; }, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<HttpResponse<any>>;
    public adminRealmsRealmClientsClientUuidNodesPost(realm: string, clientUuid: string, requestBody?: { [key: string]: string; }, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<HttpEvent<any>>;
    public adminRealmsRealmClientsClientUuidNodesPost(realm: string, clientUuid: string, requestBody?: { [key: string]: string; }, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<any> {
        if (realm === null || realm === undefined) {
            throw new Error('Required parameter realm was null or undefined when calling adminRealmsRealmClientsClientUuidNodesPost.');
        }
        if (clientUuid === null || clientUuid === undefined) {
            throw new Error('Required parameter clientUuid was null or undefined when calling adminRealmsRealmClientsClientUuidNodesPost.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/admin/realms/${this.configuration.encodeParam({name: "realm", value: realm, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/clients/${this.configuration.encodeParam({name: "clientUuid", value: clientUuid, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/nodes`;
        return this.httpClient.request<any>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: requestBody,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get application offline session count Returns a number of offline user sessions associated with this client { \&quot;count\&quot;: number }
     * @param realm realm name (not id!)
     * @param clientUuid id of client (not client-id!)
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public adminRealmsRealmClientsClientUuidOfflineSessionCountGet(realm: string, clientUuid: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<{ [key: string]: number; }>;
    public adminRealmsRealmClientsClientUuidOfflineSessionCountGet(realm: string, clientUuid: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<{ [key: string]: number; }>>;
    public adminRealmsRealmClientsClientUuidOfflineSessionCountGet(realm: string, clientUuid: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<{ [key: string]: number; }>>;
    public adminRealmsRealmClientsClientUuidOfflineSessionCountGet(realm: string, clientUuid: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (realm === null || realm === undefined) {
            throw new Error('Required parameter realm was null or undefined when calling adminRealmsRealmClientsClientUuidOfflineSessionCountGet.');
        }
        if (clientUuid === null || clientUuid === undefined) {
            throw new Error('Required parameter clientUuid was null or undefined when calling adminRealmsRealmClientsClientUuidOfflineSessionCountGet.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/admin/realms/${this.configuration.encodeParam({name: "realm", value: realm, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/clients/${this.configuration.encodeParam({name: "clientUuid", value: clientUuid, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/offline-session-count`;
        return this.httpClient.request<{ [key: string]: number; }>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get offline sessions for client Returns a list of offline user sessions associated with this client
     * @param realm realm name (not id!)
     * @param clientUuid id of client (not client-id!)
     * @param first Paging offset
     * @param max Maximum results size (defaults to 100)
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public adminRealmsRealmClientsClientUuidOfflineSessionsGet(realm: string, clientUuid: string, first?: number, max?: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<Array<UserSessionRepresentation>>;
    public adminRealmsRealmClientsClientUuidOfflineSessionsGet(realm: string, clientUuid: string, first?: number, max?: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<Array<UserSessionRepresentation>>>;
    public adminRealmsRealmClientsClientUuidOfflineSessionsGet(realm: string, clientUuid: string, first?: number, max?: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<Array<UserSessionRepresentation>>>;
    public adminRealmsRealmClientsClientUuidOfflineSessionsGet(realm: string, clientUuid: string, first?: number, max?: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (realm === null || realm === undefined) {
            throw new Error('Required parameter realm was null or undefined when calling adminRealmsRealmClientsClientUuidOfflineSessionsGet.');
        }
        if (clientUuid === null || clientUuid === undefined) {
            throw new Error('Required parameter clientUuid was null or undefined when calling adminRealmsRealmClientsClientUuidOfflineSessionsGet.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (first !== undefined && first !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>first, 'first');
        }
        if (max !== undefined && max !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>max, 'max');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/admin/realms/${this.configuration.encodeParam({name: "realm", value: realm, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/clients/${this.configuration.encodeParam({name: "clientUuid", value: clientUuid, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/offline-sessions`;
        return this.httpClient.request<Array<UserSessionRepresentation>>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param realm realm name (not id!)
     * @param clientUuid id of client (not client-id!)
     * @param clientScopeId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public adminRealmsRealmClientsClientUuidOptionalClientScopesClientScopeIdDelete(realm: string, clientUuid: string, clientScopeId: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<any>;
    public adminRealmsRealmClientsClientUuidOptionalClientScopesClientScopeIdDelete(realm: string, clientUuid: string, clientScopeId: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<HttpResponse<any>>;
    public adminRealmsRealmClientsClientUuidOptionalClientScopesClientScopeIdDelete(realm: string, clientUuid: string, clientScopeId: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<HttpEvent<any>>;
    public adminRealmsRealmClientsClientUuidOptionalClientScopesClientScopeIdDelete(realm: string, clientUuid: string, clientScopeId: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<any> {
        if (realm === null || realm === undefined) {
            throw new Error('Required parameter realm was null or undefined when calling adminRealmsRealmClientsClientUuidOptionalClientScopesClientScopeIdDelete.');
        }
        if (clientUuid === null || clientUuid === undefined) {
            throw new Error('Required parameter clientUuid was null or undefined when calling adminRealmsRealmClientsClientUuidOptionalClientScopesClientScopeIdDelete.');
        }
        if (clientScopeId === null || clientScopeId === undefined) {
            throw new Error('Required parameter clientScopeId was null or undefined when calling adminRealmsRealmClientsClientUuidOptionalClientScopesClientScopeIdDelete.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/admin/realms/${this.configuration.encodeParam({name: "realm", value: realm, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/clients/${this.configuration.encodeParam({name: "clientUuid", value: clientUuid, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/optional-client-scopes/${this.configuration.encodeParam({name: "clientScopeId", value: clientScopeId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}`;
        return this.httpClient.request<any>('delete', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param realm realm name (not id!)
     * @param clientUuid id of client (not client-id!)
     * @param clientScopeId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public adminRealmsRealmClientsClientUuidOptionalClientScopesClientScopeIdPut(realm: string, clientUuid: string, clientScopeId: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<any>;
    public adminRealmsRealmClientsClientUuidOptionalClientScopesClientScopeIdPut(realm: string, clientUuid: string, clientScopeId: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<HttpResponse<any>>;
    public adminRealmsRealmClientsClientUuidOptionalClientScopesClientScopeIdPut(realm: string, clientUuid: string, clientScopeId: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<HttpEvent<any>>;
    public adminRealmsRealmClientsClientUuidOptionalClientScopesClientScopeIdPut(realm: string, clientUuid: string, clientScopeId: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<any> {
        if (realm === null || realm === undefined) {
            throw new Error('Required parameter realm was null or undefined when calling adminRealmsRealmClientsClientUuidOptionalClientScopesClientScopeIdPut.');
        }
        if (clientUuid === null || clientUuid === undefined) {
            throw new Error('Required parameter clientUuid was null or undefined when calling adminRealmsRealmClientsClientUuidOptionalClientScopesClientScopeIdPut.');
        }
        if (clientScopeId === null || clientScopeId === undefined) {
            throw new Error('Required parameter clientScopeId was null or undefined when calling adminRealmsRealmClientsClientUuidOptionalClientScopesClientScopeIdPut.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/admin/realms/${this.configuration.encodeParam({name: "realm", value: realm, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/clients/${this.configuration.encodeParam({name: "clientUuid", value: clientUuid, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/optional-client-scopes/${this.configuration.encodeParam({name: "clientScopeId", value: clientScopeId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}`;
        return this.httpClient.request<any>('put', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get optional client scopes.  Only name and ids are returned.
     * @param realm realm name (not id!)
     * @param clientUuid id of client (not client-id!)
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public adminRealmsRealmClientsClientUuidOptionalClientScopesGet(realm: string, clientUuid: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<Array<ClientScopeRepresentation>>;
    public adminRealmsRealmClientsClientUuidOptionalClientScopesGet(realm: string, clientUuid: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<Array<ClientScopeRepresentation>>>;
    public adminRealmsRealmClientsClientUuidOptionalClientScopesGet(realm: string, clientUuid: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<Array<ClientScopeRepresentation>>>;
    public adminRealmsRealmClientsClientUuidOptionalClientScopesGet(realm: string, clientUuid: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (realm === null || realm === undefined) {
            throw new Error('Required parameter realm was null or undefined when calling adminRealmsRealmClientsClientUuidOptionalClientScopesGet.');
        }
        if (clientUuid === null || clientUuid === undefined) {
            throw new Error('Required parameter clientUuid was null or undefined when calling adminRealmsRealmClientsClientUuidOptionalClientScopesGet.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/admin/realms/${this.configuration.encodeParam({name: "realm", value: realm, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/clients/${this.configuration.encodeParam({name: "clientUuid", value: clientUuid, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/optional-client-scopes`;
        return this.httpClient.request<Array<ClientScopeRepresentation>>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Push the client\&#39;s revocation policy to its admin URL If the client has an admin URL, push revocation policy to it.
     * @param realm realm name (not id!)
     * @param clientUuid id of client (not client-id!)
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public adminRealmsRealmClientsClientUuidPushRevocationPost(realm: string, clientUuid: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<GlobalRequestResult>;
    public adminRealmsRealmClientsClientUuidPushRevocationPost(realm: string, clientUuid: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<GlobalRequestResult>>;
    public adminRealmsRealmClientsClientUuidPushRevocationPost(realm: string, clientUuid: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<GlobalRequestResult>>;
    public adminRealmsRealmClientsClientUuidPushRevocationPost(realm: string, clientUuid: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (realm === null || realm === undefined) {
            throw new Error('Required parameter realm was null or undefined when calling adminRealmsRealmClientsClientUuidPushRevocationPost.');
        }
        if (clientUuid === null || clientUuid === undefined) {
            throw new Error('Required parameter clientUuid was null or undefined when calling adminRealmsRealmClientsClientUuidPushRevocationPost.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/admin/realms/${this.configuration.encodeParam({name: "realm", value: realm, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/clients/${this.configuration.encodeParam({name: "clientUuid", value: clientUuid, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/push-revocation`;
        return this.httpClient.request<GlobalRequestResult>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update the client
     * @param realm realm name (not id!)
     * @param clientUuid id of client (not client-id!)
     * @param clientRepresentation 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public adminRealmsRealmClientsClientUuidPut(realm: string, clientUuid: string, clientRepresentation?: ClientRepresentation, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<any>;
    public adminRealmsRealmClientsClientUuidPut(realm: string, clientUuid: string, clientRepresentation?: ClientRepresentation, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<HttpResponse<any>>;
    public adminRealmsRealmClientsClientUuidPut(realm: string, clientUuid: string, clientRepresentation?: ClientRepresentation, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<HttpEvent<any>>;
    public adminRealmsRealmClientsClientUuidPut(realm: string, clientUuid: string, clientRepresentation?: ClientRepresentation, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<any> {
        if (realm === null || realm === undefined) {
            throw new Error('Required parameter realm was null or undefined when calling adminRealmsRealmClientsClientUuidPut.');
        }
        if (clientUuid === null || clientUuid === undefined) {
            throw new Error('Required parameter clientUuid was null or undefined when calling adminRealmsRealmClientsClientUuidPut.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/admin/realms/${this.configuration.encodeParam({name: "realm", value: realm, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/clients/${this.configuration.encodeParam({name: "clientUuid", value: clientUuid, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}`;
        return this.httpClient.request<any>('put', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: clientRepresentation,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Generate a new registration access token for the client
     * @param realm realm name (not id!)
     * @param clientUuid id of client (not client-id!)
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public adminRealmsRealmClientsClientUuidRegistrationAccessTokenPost(realm: string, clientUuid: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<ClientRepresentation>;
    public adminRealmsRealmClientsClientUuidRegistrationAccessTokenPost(realm: string, clientUuid: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<ClientRepresentation>>;
    public adminRealmsRealmClientsClientUuidRegistrationAccessTokenPost(realm: string, clientUuid: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<ClientRepresentation>>;
    public adminRealmsRealmClientsClientUuidRegistrationAccessTokenPost(realm: string, clientUuid: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (realm === null || realm === undefined) {
            throw new Error('Required parameter realm was null or undefined when calling adminRealmsRealmClientsClientUuidRegistrationAccessTokenPost.');
        }
        if (clientUuid === null || clientUuid === undefined) {
            throw new Error('Required parameter clientUuid was null or undefined when calling adminRealmsRealmClientsClientUuidRegistrationAccessTokenPost.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/admin/realms/${this.configuration.encodeParam({name: "realm", value: realm, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/clients/${this.configuration.encodeParam({name: "clientUuid", value: clientUuid, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/registration-access-token`;
        return this.httpClient.request<ClientRepresentation>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get a user dedicated to the service account
     * @param realm realm name (not id!)
     * @param clientUuid id of client (not client-id!)
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public adminRealmsRealmClientsClientUuidServiceAccountUserGet(realm: string, clientUuid: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<UserRepresentation>;
    public adminRealmsRealmClientsClientUuidServiceAccountUserGet(realm: string, clientUuid: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<UserRepresentation>>;
    public adminRealmsRealmClientsClientUuidServiceAccountUserGet(realm: string, clientUuid: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<UserRepresentation>>;
    public adminRealmsRealmClientsClientUuidServiceAccountUserGet(realm: string, clientUuid: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (realm === null || realm === undefined) {
            throw new Error('Required parameter realm was null or undefined when calling adminRealmsRealmClientsClientUuidServiceAccountUserGet.');
        }
        if (clientUuid === null || clientUuid === undefined) {
            throw new Error('Required parameter clientUuid was null or undefined when calling adminRealmsRealmClientsClientUuidServiceAccountUserGet.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/admin/realms/${this.configuration.encodeParam({name: "realm", value: realm, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/clients/${this.configuration.encodeParam({name: "clientUuid", value: clientUuid, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/service-account-user`;
        return this.httpClient.request<UserRepresentation>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get application session count Returns a number of user sessions associated with this client { \&quot;count\&quot;: number }
     * @param realm realm name (not id!)
     * @param clientUuid id of client (not client-id!)
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public adminRealmsRealmClientsClientUuidSessionCountGet(realm: string, clientUuid: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<{ [key: string]: number; }>;
    public adminRealmsRealmClientsClientUuidSessionCountGet(realm: string, clientUuid: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<{ [key: string]: number; }>>;
    public adminRealmsRealmClientsClientUuidSessionCountGet(realm: string, clientUuid: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<{ [key: string]: number; }>>;
    public adminRealmsRealmClientsClientUuidSessionCountGet(realm: string, clientUuid: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (realm === null || realm === undefined) {
            throw new Error('Required parameter realm was null or undefined when calling adminRealmsRealmClientsClientUuidSessionCountGet.');
        }
        if (clientUuid === null || clientUuid === undefined) {
            throw new Error('Required parameter clientUuid was null or undefined when calling adminRealmsRealmClientsClientUuidSessionCountGet.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/admin/realms/${this.configuration.encodeParam({name: "realm", value: realm, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/clients/${this.configuration.encodeParam({name: "clientUuid", value: clientUuid, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/session-count`;
        return this.httpClient.request<{ [key: string]: number; }>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Test if registered cluster nodes are available Tests availability by sending \&#39;ping\&#39; request to all cluster nodes.
     * @param realm realm name (not id!)
     * @param clientUuid id of client (not client-id!)
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public adminRealmsRealmClientsClientUuidTestNodesAvailableGet(realm: string, clientUuid: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<GlobalRequestResult>;
    public adminRealmsRealmClientsClientUuidTestNodesAvailableGet(realm: string, clientUuid: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<GlobalRequestResult>>;
    public adminRealmsRealmClientsClientUuidTestNodesAvailableGet(realm: string, clientUuid: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<GlobalRequestResult>>;
    public adminRealmsRealmClientsClientUuidTestNodesAvailableGet(realm: string, clientUuid: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (realm === null || realm === undefined) {
            throw new Error('Required parameter realm was null or undefined when calling adminRealmsRealmClientsClientUuidTestNodesAvailableGet.');
        }
        if (clientUuid === null || clientUuid === undefined) {
            throw new Error('Required parameter clientUuid was null or undefined when calling adminRealmsRealmClientsClientUuidTestNodesAvailableGet.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/admin/realms/${this.configuration.encodeParam({name: "realm", value: realm, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/clients/${this.configuration.encodeParam({name: "clientUuid", value: clientUuid, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/test-nodes-available`;
        return this.httpClient.request<GlobalRequestResult>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get user sessions for client Returns a list of user sessions associated with this client 
     * @param realm realm name (not id!)
     * @param clientUuid id of client (not client-id!)
     * @param first Paging offset
     * @param max Maximum results size (defaults to 100)
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public adminRealmsRealmClientsClientUuidUserSessionsGet(realm: string, clientUuid: string, first?: number, max?: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<Array<UserSessionRepresentation>>;
    public adminRealmsRealmClientsClientUuidUserSessionsGet(realm: string, clientUuid: string, first?: number, max?: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<Array<UserSessionRepresentation>>>;
    public adminRealmsRealmClientsClientUuidUserSessionsGet(realm: string, clientUuid: string, first?: number, max?: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<Array<UserSessionRepresentation>>>;
    public adminRealmsRealmClientsClientUuidUserSessionsGet(realm: string, clientUuid: string, first?: number, max?: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (realm === null || realm === undefined) {
            throw new Error('Required parameter realm was null or undefined when calling adminRealmsRealmClientsClientUuidUserSessionsGet.');
        }
        if (clientUuid === null || clientUuid === undefined) {
            throw new Error('Required parameter clientUuid was null or undefined when calling adminRealmsRealmClientsClientUuidUserSessionsGet.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (first !== undefined && first !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>first, 'first');
        }
        if (max !== undefined && max !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>max, 'max');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/admin/realms/${this.configuration.encodeParam({name: "realm", value: realm, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/clients/${this.configuration.encodeParam({name: "clientUuid", value: clientUuid, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/user-sessions`;
        return this.httpClient.request<Array<UserSessionRepresentation>>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get clients belonging to the realm.
     * If a client canâ€™t be retrieved from the storage due to a problem with the underlying storage, it is silently removed from the returned list. This ensures that concurrent modifications to the list donâ€™t prevent callers from retrieving this list.
     * @param realm realm name (not id!)
     * @param clientId filter by clientId
     * @param first the first result
     * @param max the max results to return
     * @param q 
     * @param search whether this is a search query or a getClientById query
     * @param viewableOnly filter clients that cannot be viewed in full by admin
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public adminRealmsRealmClientsGet(realm: string, clientId?: string, first?: number, max?: number, q?: string, search?: boolean, viewableOnly?: boolean, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<Array<ClientRepresentation>>;
    public adminRealmsRealmClientsGet(realm: string, clientId?: string, first?: number, max?: number, q?: string, search?: boolean, viewableOnly?: boolean, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<Array<ClientRepresentation>>>;
    public adminRealmsRealmClientsGet(realm: string, clientId?: string, first?: number, max?: number, q?: string, search?: boolean, viewableOnly?: boolean, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<Array<ClientRepresentation>>>;
    public adminRealmsRealmClientsGet(realm: string, clientId?: string, first?: number, max?: number, q?: string, search?: boolean, viewableOnly?: boolean, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (realm === null || realm === undefined) {
            throw new Error('Required parameter realm was null or undefined when calling adminRealmsRealmClientsGet.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (clientId !== undefined && clientId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>clientId, 'clientId');
        }
        if (first !== undefined && first !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>first, 'first');
        }
        if (max !== undefined && max !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>max, 'max');
        }
        if (q !== undefined && q !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>q, 'q');
        }
        if (search !== undefined && search !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>search, 'search');
        }
        if (viewableOnly !== undefined && viewableOnly !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>viewableOnly, 'viewableOnly');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/admin/realms/${this.configuration.encodeParam({name: "realm", value: realm, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/clients`;
        return this.httpClient.request<Array<ClientRepresentation>>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Create a new client Clientâ€™s client_id must be unique!
     * @param realm realm name (not id!)
     * @param clientRepresentation 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public adminRealmsRealmClientsPost(realm: string, clientRepresentation?: ClientRepresentation, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<any>;
    public adminRealmsRealmClientsPost(realm: string, clientRepresentation?: ClientRepresentation, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<HttpResponse<any>>;
    public adminRealmsRealmClientsPost(realm: string, clientRepresentation?: ClientRepresentation, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<HttpEvent<any>>;
    public adminRealmsRealmClientsPost(realm: string, clientRepresentation?: ClientRepresentation, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<any> {
        if (realm === null || realm === undefined) {
            throw new Error('Required parameter realm was null or undefined when calling adminRealmsRealmClientsPost.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/admin/realms/${this.configuration.encodeParam({name: "realm", value: realm, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/clients`;
        return this.httpClient.request<any>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: clientRepresentation,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
