import { Injectable, inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import {
  AutoLoginPartialRoutesGuard,
  OidcSecurityService,
} from 'angular-auth-oidc-client';
import { Observable, of } from 'rxjs';
import { take, map, switchMap } from 'rxjs/operators';
import { PathNames, Paths } from '@constant/routes';
import { AuthOidcService } from './auth-oidc.service';

enum RoutePermission {
  unauthorized = 1,
  forbidden = 2,
  allowed = 0,
}

@Injectable({ providedIn: 'root' })
export class AuthOidcGuard implements CanActivate {
  private readonly router = inject(Router);
  private readonly oidcService = inject(OidcSecurityService);
  private readonly authOidcService = inject(AuthOidcService);
  private readonly autoLoginPartialRoutesGuard = inject(
    AutoLoginPartialRoutesGuard
  );

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | boolean {
    // auto login - will redirect to IDP login when unauthenticated
    // return this.autoLoginPartialRoutesGuard.canActivate(route, state).pipe(
    //   take(1),
    //   map(isAuthorized => {
    //     if (isAuthorized) {
    //       // add role check here
    //       console.warn('oidc route guard authenticated: ', isAuthorized);
    //       return true;
    //     }
    //     console.warn('oidc route guard authenticated: ', isAuthorized);
    //     this.router.parseUrl(`/${PathNames.login}`);
    //     return false;
    //   })
    // );

    // auth pipe
    return this.oidcService.isAuthenticated$.pipe(
      take(1),
      switchMap(({ isAuthenticated }) => {
        console.warn('oidc route guard authenticated: ', isAuthenticated);
        if (isAuthenticated) {
          // add role check
          if (route?.data?.role) {
            return this.checkUserRole(route);
          }
          return of(RoutePermission.allowed);
        }
        return of(RoutePermission.unauthorized);
      }),
      map((permission: RoutePermission) => {
        if (permission === RoutePermission.allowed) return true;
        if (permission === RoutePermission.forbidden) {
          // console.warn(
          //   'AUTH GUARD - Check Role: unauthorized - return to home page',
          //   PathNames.home
          // );
          this.router.navigate([Paths.home]);
          return false;
        }
        // console.warn(
        //   'AUTH GUARD - unauthenticated - return to login page',
        //   PathNames.home
        // );
        this.router.navigate([Paths.login]);
        return false;
      })
    );
    // if (this.autoLoginPartialRoutesGuard.canActivate(route, state)) {
    //   // add role check here
    //   console.warn('oidc route guard authenticated: ', true);
    //   return true;
    // }
    // console.warn('oidc route guard authenticated: ', false);
    // this.router.parseUrl(`/${PathNames.login}`);
    // return false;

    // return this.authOidcService.checkIfAllowed().pipe(
    //   take(1),
    //   map(({ isAuthenticated }) => {
    //     if (isAuthenticated) {
    //       return true;
    //     }

    //     return this.router.parseUrl(`/${PathNames.login}`);
    //   })
    // );
  }

  checkUserRole(route: ActivatedRouteSnapshot) {
    return this.authOidcService.getUserAppRole().pipe(
      take(1),
      map(role => {
        const routeRoles = route.data.role;
        if (routeRoles && routeRoles.indexOf(role) === -1) {
          // console.warn(
          //   'AUTH GUARD - Check Role: unauthorized - return to home page',
          //   PathNames.home
          // );
          return RoutePermission.forbidden;
        }
        return RoutePermission.allowed;
      })
    );
  }
}
