import { NgModule } from '@angular/core';
import {
  AbstractSecurityStorage,
  AuthModule as AuthOidcModule,
  DefaultLocalStorageService,
  DefaultSessionStorageService,
  LogLevel,
  PublicEventsService,
  EventTypes,
} from 'angular-auth-oidc-client';
import { environment } from 'environments/environment';
import { Paths } from '@constant/routes';
import { AuthOidcGuard } from './auth-oidc-guard.service';
import { AuthOidcService } from './auth-oidc.service';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthOidcInterceptor } from './auth-oidc.interceptor';
import { filter } from 'rxjs/operators';

@NgModule({
  imports: [
    AuthOidcModule.forRoot({
      config: {
        configId: environment.keycloak.configId,
        authority: environment.keycloak.authority,
        redirectUrl: environment.keycloak.redirectUrl,
        clientId: environment.keycloak.clientId,
        // scope: 'openid profile offline_access',
        // scope: 'openid profile email offline_access',
        scope: 'openid profile email',
        postLogoutRedirectUri: environment.keycloak.postLogoutRedirectUri,
        postLoginRoute: Paths.home,
        forbiddenRoute: Paths.login,
        unauthorizedRoute: Paths.login,

        // startCheckSession: true,
        autoUserInfo: true,

        responseType: 'code',
        silentRenew: true,
        silentRenewUrl: '/silent-renew.html',
        useRefreshToken: true,
        ignoreNonceAfterRefresh: true,

        maxIdTokenIatOffsetAllowedInSeconds: 600,

        // renewTimeBeforeTokenExpiresInSeconds:
        //   Math.floor(Math.random() * 300) + 30, // max of 5 mins + 1 min - need to be less than keycloak token lifespan

        // renewTimeBeforeTokenExpiresInSeconds:
        //   Math.floor(Math.random() * 30) + 60, // max of 30s + 1 min - need to be less than keycloak token lifespan
        triggerRefreshWhenIdTokenExpired: true,
        logLevel: LogLevel.Debug,

        // attach token to these URLs
        secureRoutes: [
          environment.keycloak.admin,
          environment.keycloak.userUrl,
          environment.apiTpsUri,
          environment.apiBaseUri,
          environment.apiUserUri,
        ],
        customParamsAuthRequest: {
          prompt: 'select_account',
          kc_idp_hint: 'AzureSAML', // use IDP alias to redirect immediately to external IDP
          ForceAuthn: true,
        },
        customParamsCodeRequest: {
          prompt: 'select_account',
          kc_idp_hint: 'AzureSAML', // use IDP alias to redirect immediately to external IDP
          ForceAuthn: true,
        },
      },
    }),
  ],
  providers: [
    {
      provide: AbstractSecurityStorage,
      useClass: DefaultLocalStorageService,
    },
    PublicEventsService,
    AuthOidcGuard,
    AuthOidcService,
    { provide: HTTP_INTERCEPTORS, useClass: AuthOidcInterceptor, multi: true },
  ],
  exports: [AuthOidcModule],
})
export class AuthOidcConfigModule {
  constructor(private readonly eventService: PublicEventsService) {
    this.eventService
      .registerForEvents()
      .pipe(
        filter(notification => notification.type === EventTypes.ConfigLoaded)
      )
      .subscribe(config => {
        console.log('Oidc Config Loaded', config);
      });
  }
}
