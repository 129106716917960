import { Injectable, inject } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpHeaders,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, BehaviorSubject, throwError, Subject, from } from 'rxjs';
import { catchError, switchMap, map, tap, finalize } from 'rxjs/operators';
import { AuthOidcService } from './auth-oidc.service';
import {
  OidcSecurityService,
  OpenIdConfiguration,
} from 'angular-auth-oidc-client';
import { Paths } from '@constant/routes';
import { Router } from '@angular/router';

function isSecuredRoute(
  route: string,
  config: OpenIdConfiguration | null
): boolean {
  if (!config) return false;
  // if (route.includes('openid-configuration')) return false;
  const { secureRoutes } = config;
  if (secureRoutes && secureRoutes.length > 0) {
    return (
      secureRoutes.some(configuredRoute => route.startsWith(configuredRoute)) ??
      false
    );
  }
  return false;
}

@Injectable()
export class AuthOidcInterceptor implements HttpInterceptor {
  private readonly oidcService = inject(OidcSecurityService);
  private readonly authOidcService = inject(AuthOidcService);
  private readonly router = inject(Router);

  isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(
    null
  );

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return this.oidcService.getConfiguration().pipe(
      switchMap(config => {
        const route = req.url;
        const isSecured = isSecuredRoute(route, config);
        if (isSecured) {
          // console.warn('Intercept request route: ', route);
          return this.oidcService.getAccessToken().pipe(
            tap(token => {
              if (!token) {
                this.router.navigate([Paths.login]);
              }
            }),
            map(token =>
              req.clone({ setHeaders: { Authorization: `Bearer ${token}` } })
            ),
            switchMap(authRequest => next.handle(authRequest)),
            catchError((error, caught) => {
              console.warn(`Intercept Next handle Error: `, error, caught);
              if (error instanceof HttpErrorResponse) {
                if (error.status === 401) {
                  if (!this.isRefreshing) {
                    this.isRefreshing = true;
                    this.refreshTokenSubject.next(null);
                    console.warn(`Intercept: Token Refresh in Progress`);
                    return this.oidcService.forceRefreshSession().pipe(
                      switchMap(result => {
                        console.warn(`Intercept: Token Refresh Result`, result);
                        const { isAuthenticated, accessToken, errorMessage } =
                          result;
                        if (isAuthenticated) {
                          const newRequest = req.clone({
                            setHeaders: {
                              Authorization: `Bearer ${accessToken}`,
                            },
                          });
                          console.warn(`Intercept: Token Refresh next result`);
                          return next.handle(newRequest);
                        }
                        if (errorMessage)
                          console.warn(
                            'Intercept: Renew error: ',
                            errorMessage
                          );

                        return caught;
                      }),
                      catchError((err, caught) => {
                        console.warn(
                          'Intercept Renew error: ',
                          err,
                          error,
                          caught
                        );
                        this.authOidcService.logoutLocal();
                        throw error;
                      }),
                      finalize(() => {
                        this.isRefreshing = false;
                      })
                    );
                  } else {
                    return next.handle(req);
                  }
                } else return throwError(error);
              }
              return caught;
            })
          );

          // const request = this.attachToken(req, next);
          // return request.pipe(
          //   catchError((error, caught) => {
          //     console.warn('pipe error', error);
          //     if (error instanceof HttpErrorResponse && error.status === 401) {
          //       return this.authOidcService.forceRefresh().pipe(
          //         switchMap((result) => {
          //           console.warn('Interceptor Session Refreshed: ', result);
          //           const { accessToken } = result;
          //           const newRequest = req.clone({
          //             setHeaders: { Authorization: `Bearer ${accessToken}` },
          //           });
          //           return next.handle(newRequest);
          //         })
          //       );
          //       // return this.refreshToken().pipe(
          //       //   switchMap(() => {
          //       //     const newRequest = this.attachToken(req, next);
          //       //     return newRequest;
          //       //   })
          //       // );
          //       // return this.handle401Error(req, next);
          //     } else {
          //       if (error.status === '403') {
          //         this.authOidcService.logout();
          //       }
          //       return caught;
          //     }
          //     return caught;
          //   })
          // );
        }
        return next.handle(req);
      })
    );
  }

  attachToken(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpRequest<any>> {
    return this.oidcService.getAccessToken().pipe(
      map(token => {
        if (token) {
          const newRequest = req.clone({
            setHeaders: { Authorization: `Bearer ${token}` },
          });
          return newRequest;
        }
        return req;
      })
    );
  }

  refreshTokenInProgress = false;

  tokenRefreshedSource: Subject<any> = new Subject();
  tokenRefreshed$ = this.tokenRefreshedSource.asObservable();

  handle401Error(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (!this.refreshTokenInProgress) {
      this.refreshTokenInProgress = true;

      return this.authOidcService.refreshSession().pipe(
        switchMap(() => {
          this.refreshTokenInProgress = false;
          return next.handle(req);
        }),
        catchError(err => {
          this.refreshTokenInProgress = false;
          if (err.status === '403') {
            this.authOidcService.logout();
          }
          return throwError(() => err);
        })
      );
    }
    return next.handle(req);
  }

  refreshToken() {
    // if (this.refreshTokenInProgress) {
    //     return new Observable()
    // }
    // this.tokenRefreshedSource.subscribe({
    //   complete: () => {
    //     this.tokenRefreshedSource = new Subject();
    //   },
    // });
    // if (this.tokenRefreshedSource.observed) {
    //   this.authOidcService.refreshSession().subscribe(this.tokenRefreshedSource);
    // }
    // return this.tokenRefreshedSource;
  }
}
